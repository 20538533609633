import React, { FC } from 'react'
import { ButtonSize, ButtonType } from 'atomic/components/atoms/button/Button'
import { ThemeColors, ThemeFontSizes, themeVars } from 'atomic/styles/theme.css'
import { IllustrationType } from 'atomic/components/atoms/illustrations/Illustration'
import DefaultHero from './layouts/default_hero/DefaultHero'
import FullbleedHero from './layouts/fullbleed_hero/FullbleedHero'
import IllustrationHero from './layouts/illustration_hero/IllustrationHero'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'
import ProductHero, { HeroSlide, ProductType } from './layouts/product_hero/ProductHero'

export type HeroButton = {
  label: string
  type: ButtonType
  link: string
  size: ButtonSize
  darkMode: boolean
}

export type HeroVideo = {
  videosrc: string
  loop: boolean
  muted: boolean
  fileType: 'mp4' | 'webm' | 'mov'
  posterImage: string
  posterMobileImage: string
}

export type HeroQuote = {
  text: string
  signature: string
  color: keyof ThemeColors
}

type PageHeroProps = {
  layout: 'default' | 'fullbleed' | 'illustration' | 'product'
  heading: string
  headingLineHeight?: keyof typeof themeVars.lineHeights
  useRichTextHeading: boolean
  richTextHeading: string
  headingSize: keyof ThemeFontSizes
  uppercase: boolean
  intro: string
  useRichTextIntro: boolean
  richTextIntro: string
  introSize: keyof ThemeFontSizes
  useVideo: boolean
  videoSettings: HeroVideo
  image: string
  altText: string
  overlayOpacity: number
  imagePosition: 'center' | 'top' | 'bottom' | 'left' | 'right'
  detailedImagePosition: number
  illustration: IllustrationType
  backgroundColor?: keyof ThemeColors
  useButton: boolean
  button: HeroButton
  addQuote: boolean
  quote: HeroQuote
  addBadge?: boolean
  mirroredLayout?: boolean
  productType?: ProductType
  heroSlides?: HeroSlide[]
  startCreateButtonText?: string
  infoList?: keyof ThemeColors
  useFullbleedLayout?: boolean
} & BuilderProps

const PageHero: FC<PageHeroProps> = ({
  layout = 'default',
  heading,
  headingLineHeight = 'xs',
  useRichTextHeading = false,
  richTextHeading,
  uppercase = false,
  headingSize = 'xxl',
  intro,
  useRichTextIntro = false,
  richTextIntro,
  introSize = 'l',
  useVideo = false,
  videoSettings,
  image,
  altText,
  overlayOpacity,
  imagePosition = 'center',
  detailedImagePosition,
  illustration,
  backgroundColor,
  useButton = false,
  button,
  addQuote = false,
  quote,
  addBadge = false,
  mirroredLayout = false,
  productType,
  heroSlides,
  startCreateButtonText,
  infoList,
  useFullbleedLayout = false,
  ...props
}) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  return (
    <>
      {layout === 'default' && (
        <DefaultHero
          heading={heading}
          intro={intro}
          image={image}
          altText={altText}
          imagePosition={imagePosition}
          detailedImagePosition={detailedImagePosition}
          quote={addQuote ? quote : undefined}
          richTextIntro={useRichTextIntro ? richTextIntro : undefined}
          headingSize={headingSize}
          fontSize={introSize}
          button={useButton ? button : undefined}
          {...cleanProps}
        />
      )}
      {layout === 'fullbleed' && (
        <FullbleedHero
          heading={heading}
          headingLineHeight={headingLineHeight}
          intro={intro}
          image={image}
          overlayOpacity={overlayOpacity}
          imagePosition={imagePosition}
          detailedImagePosition={detailedImagePosition}
          richTextHeading={useRichTextHeading ? richTextHeading : undefined}
          richTextIntro={useRichTextIntro ? richTextIntro : undefined}
          uppercase={uppercase}
          headingSize={headingSize}
          fontSize={introSize}
          button={useButton ? button : undefined}
          useVideo={useVideo}
          videoSettings={useVideo ? videoSettings : undefined}
          altText={altText}
          {...cleanProps}
        />
      )}
      {layout === 'illustration' && (
        <IllustrationHero
          illustration={illustration}
          backgroundColor={backgroundColor ?? undefined}
          heading={heading}
          intro={intro}
          richTextIntro={useRichTextIntro ? richTextIntro : undefined}
          headingSize={headingSize}
          fontSize={introSize}
          button={useButton ? button : undefined}
          addBadge={addBadge}
          mirroredLayout={mirroredLayout}
          useFullbleedLayout={useFullbleedLayout}
          {...cleanProps}
        />
      )}
      {layout === 'product' && (
        <ProductHero
          heading={heading}
          intro={intro}
          productType={productType}
          startCreateButtonText={startCreateButtonText}
          heroSlides={heroSlides}
          infoList={infoList}
          {...cleanProps}
        />
      )}
    </>
  )
}

export default PageHero

import { Box, Container, Heading, Text } from 'atomic'
import React, { FC } from 'react'
import Image from 'next/image'
import { ouTheme, ThemeFontSizes } from 'atomic/styles/theme.css'
import { Link } from 'components/link/Link'
import Button from 'atomic/components/atoms/button/Button'
import * as css from './DefaultHero.css'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'
import { HeroButton, HeroQuote } from '../../PageHero'

type DefaultHeroProps = {
  heading: string
  intro: string
  image: string
  altText?: string
  imagePosition: 'center' | 'top' | 'bottom' | 'left' | 'right'
  detailedImagePosition: number
  quote?: HeroQuote
  richTextIntro: string
  headingSize: keyof ThemeFontSizes
  fontSize: keyof ThemeFontSizes
  button: HeroButton
} & BuilderProps

const DefaultHero: FC<DefaultHeroProps> = ({
  heading,
  intro,
  image,
  altText,
  imagePosition,
  detailedImagePosition,
  quote,
  richTextIntro,
  headingSize,
  fontSize,
  button,
  ...props
}) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  return (
    <Box position={'relative'} {...cleanProps}>
      <Box
        className={css.image}
        position={{ mobile: 'relative', desktop: 'absolute' }}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        background="mossa2"
      >
        <Image
          fill
          src={image}
          alt={altText}
          style={{
            objectFit: 'cover',
            objectPosition:
              imagePosition && detailedImagePosition ? `${detailedImagePosition}% ${imagePosition}` : imagePosition ? imagePosition : 'center',
          }}
          sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 50vw`}
          priority={true}
          fetchPriority="high"
        />
        {quote && (
          <Box
            style={{ maxWidth: 685 }}
            position={'absolute'}
            display={'flex'}
            flexDirection={'column'}
            gap={{ mobile: 's', desktop: 'l' }}
            marginTop={'xxxl'}
            paddingX={{ mobile: 'l', desktop: 'none' }}
          >
            <Heading as="h3" fontSize={{ mobile: 'xxl', desktop: 'xxxl' }} color={quote.color ?? 'white'} textAlign={'center'} marginBottom={'none'}>
              {quote.text}
            </Heading>
            <Text
              fontSize={{ mobile: 'l', desktop: 'xl' }}
              fontWeight={'normal'}
              color={quote.color ?? 'white'}
              textAlign={'center'}
              marginBottom={'none'}
            >
              {quote.signature}
            </Text>
          </Box>
        )}
      </Box>
      <Container className={css.container}>
        {/* Empty column to match layout */}
        <div />
        <Box
          paddingLeft={{ mobile: 'none', desktop: 'xxl' }}
          paddingY={{ mobile: 'l', desktop: 'xxxl' }}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-start'}
          justifyContent={'center'}
          gap={{ mobile: 'l', desktop: 'xxxl' }}
        >
          <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} gap={'m'}>
            <Heading as={'h1'} fontSize={headingSize ?? 'xxl'} margin={'none'} fontWeight={'extrabold'}>
              {heading}
            </Heading>
            {!richTextIntro ? (
              <Text fontWeight={'light'} fontSize={fontSize ?? 'l'} margin={'none'}>
                {intro}
              </Text>
            ) : (
              <Box fontSize={fontSize ?? 'l'} fontWeight={'light'} margin={'none'} dangerouslySetInnerHTML={{ __html: richTextIntro }} />
            )}
            {button && (
              <Link href={button?.link}>
                <Button variant={button?.type} size={button?.size}>
                  {button?.label}
                </Button>
              </Link>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default DefaultHero

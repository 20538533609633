import { Box, Container, Heading, Text } from 'atomic'
import Illustration from 'atomic/components/atoms/illustrations/Illustration'
import { Link } from 'components/link/Link'
import Button from 'atomic/components/atoms/button/Button'
import React, { FC } from 'react'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'
import * as css from './IllustrationHero.css'
import { ThemeColors, ThemeFontSizes } from 'atomic/styles/theme.css'
import { IllustrationType } from 'atomic/components/atoms/illustrations/Illustration'
import { HeroButton } from '../../PageHero'
import { CustomerSatisfaction } from 'components/icons/CustomerSatisfaction'

type IllustrationHeroProps = {
  backgroundColor?: keyof ThemeColors
  illustration: IllustrationType
  heading: string
  intro: string
  richTextIntro: string
  headingSize: keyof ThemeFontSizes
  fontSize: keyof ThemeFontSizes
  button: HeroButton
  addBadge?: boolean
  mirroredLayout?: boolean
  useFullbleedLayout: boolean
} & BuilderProps

const IllustrationHero: FC<IllustrationHeroProps> = ({
  backgroundColor,
  illustration,
  heading,
  intro,
  richTextIntro,
  headingSize,
  fontSize,
  button,
  addBadge = false,
  mirroredLayout = false,
  useFullbleedLayout,
  ...props
}) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  return (
    <>
      {useFullbleedLayout ? (
        <Box style={{ width: '100%' }} backgroundColor={backgroundColor}>
          <Container display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} {...cleanProps}>
            {mirroredLayout && (
              <Box
                position={'relative'}
                alignItems={'center'}
                gap={{ mobile: 'm', desktop: 'xxl' }}
                className={css.container[mirroredLayout ? 'mirrored' : 'default']}
                backgroundColor={backgroundColor}
                paddingTop={'xxxl'}
                paddingBottom={{ mobile: 'm', desktop: 'xxl' }}
              >
                <Box display={'flex'} flexDirection={'column'} alignItems={{ mobile: 'center', desktop: 'flex-start' }} gap={'l'}>
                  <Box display={'flex'} flexDirection={'column'} alignItems={{ mobile: 'center', desktop: 'flex-start' }} gap={'s'}>
                    <Heading
                      as={'h1'}
                      fontSize={headingSize ?? 'xxl'}
                      fontWeight={'extrabold'}
                      marginBottom={'none'}
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                    >
                      {heading}
                    </Heading>
                    {!richTextIntro ? (
                      <Text fontSize={fontSize ?? 'base'} fontWeight={'light'} textAlign={{ mobile: 'center', desktop: 'left' }}>
                        {intro}
                      </Text>
                    ) : (
                      <Box
                        fontSize={fontSize ?? 'base'}
                        fontWeight={'light'}
                        textAlign={{ mobile: 'center', desktop: 'left' }}
                        dangerouslySetInnerHTML={{ __html: richTextIntro }}
                      />
                    )}
                  </Box>
                  {button && (
                    <Link href={button?.link}>
                      <Button
                        variant={button?.type}
                        size={button?.size}
                        style={
                          (button.darkMode && button?.type === 'secondary') || button?.type === 'toggle'
                            ? { borderColor: 'white', color: 'white' }
                            : button.darkMode && button?.type === 'primary'
                              ? { backgroundColor: 'white', color: '#222' }
                              : {}
                        }
                      >
                        {button?.label}
                      </Button>
                    </Link>
                  )}
                </Box>

                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} paddingX={{ mobile: 's', desktop: 'none' }}>
                  <Illustration name={illustration ?? 'heart-hug'} width={300} />
                </Box>
                {addBadge && (
                  <Box position={{ mobile: 'relative', desktop: 'absolute' }} className={css.customerSatisfactionBadge}>
                    <CustomerSatisfaction width={139} height={139} />
                  </Box>
                )}
              </Box>
            )}
            {!mirroredLayout && (
              <Box
                position={'relative'}
                alignItems={'center'}
                gap={{ mobile: 'm', desktop: 'xxl' }}
                className={css.container[mirroredLayout ? 'mirrored' : 'default']}
                backgroundColor={backgroundColor}
                paddingTop={'xxxl'}
                paddingBottom={{ mobile: 'm', desktop: 'xxl' }}
              >
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} paddingX={{ mobile: 's', desktop: 'none' }}>
                  <Illustration name={illustration ?? 'heart-hug'} width={300} />
                </Box>
                <Box display={'flex'} flexDirection={'column'} alignItems={{ mobile: 'center', desktop: 'flex-start' }} gap={'l'}>
                  <Box display={'flex'} flexDirection={'column'} alignItems={{ mobile: 'center', desktop: 'flex-start' }} gap={'s'}>
                    <Heading
                      as={'h1'}
                      fontSize={headingSize ?? 'xxl'}
                      fontWeight={'extrabold'}
                      marginBottom={'none'}
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                    >
                      {heading}
                    </Heading>
                    {!richTextIntro ? (
                      <Text fontSize={fontSize ?? 'base'} fontWeight={'light'} textAlign={{ mobile: 'center', desktop: 'left' }}>
                        {intro}
                      </Text>
                    ) : (
                      <Box
                        fontSize={fontSize ?? 'base'}
                        fontWeight={'light'}
                        textAlign={{ mobile: 'center', desktop: 'left' }}
                        dangerouslySetInnerHTML={{ __html: richTextIntro }}
                      />
                    )}
                  </Box>
                  {button && (
                    <Link href={button?.link}>
                      <Button
                        variant={button?.type}
                        size={button?.size}
                        style={
                          (button.darkMode && button?.type === 'secondary') || button?.type === 'toggle'
                            ? { borderColor: 'white', color: 'white' }
                            : button.darkMode && button?.type === 'primary'
                              ? { backgroundColor: 'white', color: '#222' }
                              : {}
                        }
                      >
                        {button?.label}
                      </Button>
                    </Link>
                  )}
                </Box>
                {addBadge && (
                  <Box position={{ mobile: 'relative', desktop: 'absolute' }} className={css.customerSatisfactionBadge}>
                    <CustomerSatisfaction width={139} height={139} />
                  </Box>
                )}
              </Box>
            )}
          </Container>
        </Box>
      ) : (
        <Container display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} {...cleanProps}>
          {mirroredLayout && (
            <Box
              position={'relative'}
              alignItems={'center'}
              gap={{ mobile: 'm', desktop: 'xxl' }}
              className={css.container[mirroredLayout ? 'mirrored' : 'default']}
              backgroundColor={backgroundColor}
              paddingTop={'xxxl'}
              paddingBottom={{ mobile: 'm', desktop: 'xxl' }}
            >
              <Box display={'flex'} flexDirection={'column'} alignItems={{ mobile: 'center', desktop: 'flex-start' }} gap={'l'}>
                <Box display={'flex'} flexDirection={'column'} alignItems={{ mobile: 'center', desktop: 'flex-start' }} gap={'s'}>
                  <Heading
                    as={'h1'}
                    fontSize={headingSize ?? 'xxl'}
                    fontWeight={'extrabold'}
                    marginBottom={'none'}
                    textAlign={{ mobile: 'center', desktop: 'left' }}
                  >
                    {heading}
                  </Heading>
                  {!richTextIntro ? (
                    <Text fontSize={fontSize ?? 'base'} fontWeight={'light'} textAlign={{ mobile: 'center', desktop: 'left' }}>
                      {intro}
                    </Text>
                  ) : (
                    <Box
                      fontSize={fontSize ?? 'base'}
                      fontWeight={'light'}
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      dangerouslySetInnerHTML={{ __html: richTextIntro }}
                    />
                  )}
                </Box>
                {button && (
                  <Link href={button?.link}>
                    <Button
                      variant={button?.type}
                      size={button?.size}
                      style={
                        (button.darkMode && button?.type === 'secondary') || button?.type === 'toggle'
                          ? { borderColor: 'white', color: 'white' }
                          : button.darkMode && button?.type === 'primary'
                            ? { backgroundColor: 'white', color: '#222' }
                            : {}
                      }
                    >
                      {button?.label}
                    </Button>
                  </Link>
                )}
              </Box>

              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} paddingX={{ mobile: 's', desktop: 'none' }}>
                <Illustration name={illustration ?? 'heart-hug'} width={300} />
              </Box>
              {addBadge && (
                <Box position={{ mobile: 'relative', desktop: 'absolute' }} className={css.customerSatisfactionBadge}>
                  <CustomerSatisfaction width={139} height={139} />
                </Box>
              )}
            </Box>
          )}
          {!mirroredLayout && (
            <Box
              position={'relative'}
              alignItems={'center'}
              gap={{ mobile: 'm', desktop: 'xxl' }}
              className={css.container[mirroredLayout ? 'mirrored' : 'default']}
              backgroundColor={backgroundColor}
              paddingTop={'xxxl'}
              paddingBottom={{ mobile: 'm', desktop: 'xxl' }}
            >
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} paddingX={{ mobile: 's', desktop: 'none' }}>
                <Illustration name={illustration ?? 'heart-hug'} width={300} />
              </Box>
              <Box display={'flex'} flexDirection={'column'} alignItems={{ mobile: 'center', desktop: 'flex-start' }} gap={'l'}>
                <Box display={'flex'} flexDirection={'column'} alignItems={{ mobile: 'center', desktop: 'flex-start' }} gap={'s'}>
                  <Heading
                    as={'h1'}
                    fontSize={headingSize ?? 'xxl'}
                    fontWeight={'extrabold'}
                    marginBottom={'none'}
                    textAlign={{ mobile: 'center', desktop: 'left' }}
                  >
                    {heading}
                  </Heading>
                  {!richTextIntro ? (
                    <Text fontSize={fontSize ?? 'base'} fontWeight={'light'} textAlign={{ mobile: 'center', desktop: 'left' }}>
                      {intro}
                    </Text>
                  ) : (
                    <Box
                      fontSize={fontSize ?? 'base'}
                      fontWeight={'light'}
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      dangerouslySetInnerHTML={{ __html: richTextIntro }}
                    />
                  )}
                </Box>
                {button && (
                  <Link href={button?.link}>
                    <Button
                      variant={button?.type}
                      size={button?.size}
                      style={
                        (button.darkMode && button?.type === 'secondary') || button?.type === 'toggle'
                          ? { borderColor: 'white', color: 'white' }
                          : button.darkMode && button?.type === 'primary'
                            ? { backgroundColor: 'white', color: '#222' }
                            : {}
                      }
                    >
                      {button?.label}
                    </Button>
                  </Link>
                )}
              </Box>
              {addBadge && (
                <Box position={{ mobile: 'relative', desktop: 'absolute' }} className={css.customerSatisfactionBadge}>
                  <CustomerSatisfaction width={139} height={139} />
                </Box>
              )}
            </Box>
          )}
        </Container>
      )}
    </>
  )
}

export default IllustrationHero

import { Box, Button, Container, Heading, Text } from 'atomic'
import React, { FC } from 'react'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'
import * as css from './FullbleedHero.css'
import { ouTheme, ThemeFontSizes, themeVars } from 'atomic/styles/theme.css'
import { HeroButton, HeroVideo } from '../../PageHero'
import Image from 'next/image'
import { Link } from 'components/link/Link'

type FullbleedHeroProps = {
  heading: string
  richTextHeading: string
  headingSize: keyof ThemeFontSizes
  uppercase: boolean
  headingLineHeight?: keyof typeof themeVars.lineHeights
  intro: string
  richTextIntro: string
  fontSize: keyof ThemeFontSizes
  image: string
  altText?: string
  overlayOpacity: number
  imagePosition: 'center' | 'top' | 'bottom' | 'left' | 'right'
  detailedImagePosition: number
  useVideo: boolean
  videoSettings?: HeroVideo
  button: HeroButton
} & BuilderProps

const FullbleedHero: FC<FullbleedHeroProps> = ({
  heading,
  richTextHeading,
  headingSize,
  uppercase,
  headingLineHeight,
  intro,
  richTextIntro,
  fontSize,
  image,
  altText,
  overlayOpacity,
  imagePosition = 'center',
  detailedImagePosition,
  useVideo = false,
  videoSettings,
  button,
  ...props
}) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  const overlay = overlayOpacity * 0.01

  return (
    <Box style={{ width: '100%' }} {...cleanProps}>
      <Box
        display={'flex'}
        position={'relative'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        className={css.imageContainer}
      >
        {!useVideo && (
          <Image
            src={image}
            fill
            alt={altText}
            style={{
              objectFit: 'cover',
              objectPosition:
                imagePosition && detailedImagePosition ? `${detailedImagePosition}% ${imagePosition}` : imagePosition ? imagePosition : 'center',
            }}
            sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 50vw`}
            priority={true}
            fetchPriority="high"
          />
        )}
        {useVideo && videoSettings && (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video
            className={css.fullBleedVideo}
            poster={videoSettings?.posterImage}
            muted={videoSettings?.muted}
            loop={videoSettings?.loop}
            playsInline
            autoPlay
            preload={'metadata'}
          >
            <source src={videoSettings.videosrc} type={`video/${videoSettings.fileType ?? 'mp4'}`} />
          </video>
        )}
        <Box position={'absolute'} className={css.gradientOverlay} style={{ opacity: overlay }} />
        <Container className={css.container} position={'relative'} padding={{ mobile: 'l', desktop: 'xxl' }}>
          {/* Empty column to match layout */}
          <div />
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={'xxl'}>
            <Box display={'flex'} flexDirection={'column'} gap={'m'} alignItems={'center'} justifyContent={'center'}>
              {!richTextHeading ? (
                <Heading
                  as={'h1'}
                  fontSize={headingSize ?? 'xxxl'}
                  margin={'none'}
                  textAlign={'center'}
                  color={'white'}
                  fontWeight={'extrabold'}
                  lineHeight={headingLineHeight ?? 'xs'}
                  style={{ textTransform: uppercase ? 'uppercase' : 'initial' }}
                >
                  {heading}
                </Heading>
              ) : (
                <h1
                  className={css.richTextHeading}
                  style={{
                    textTransform: uppercase ? 'uppercase' : 'initial',
                    fontSize: headingSize ?? themeVars.fontSizes.xxxl,
                    lineHeight: headingLineHeight ?? 'xs',
                  }}
                  dangerouslySetInnerHTML={{ __html: richTextHeading }}
                />
              )}
              {!richTextIntro ? (
                <Text fontWeight={'normal'} textAlign={'center'} margin={'none'} fontSize={fontSize ?? 'l'} color={'white'}>
                  {intro}
                </Text>
              ) : (
                <Box
                  fontWeight={'normal'}
                  textAlign={'center'}
                  margin={'none'}
                  fontSize={fontSize ?? 'l'}
                  color={'white'}
                  dangerouslySetInnerHTML={{ __html: richTextIntro }}
                />
              )}
              {button && (
                <Link href={button?.link} style={{ paddingTop: themeVars.spaces.m }}>
                  <Button
                    variant={button?.type}
                    size={button?.size}
                    style={
                      (button.darkMode && button?.type === 'secondary') || button?.type === 'toggle'
                        ? { borderColor: 'white', color: 'white' }
                        : button.darkMode && button?.type === 'primary'
                          ? { backgroundColor: 'white', color: '#222' }
                          : {}
                    }
                  >
                    {button?.label}
                  </Button>
                </Link>
              )}
            </Box>
          </Box>
          {/* Empty column to match layout */}
          <div />
        </Container>
      </Box>
    </Box>
  )
}

export default FullbleedHero

import { Box, Carousel, Container, Heading, Text } from 'atomic'
import { ouTheme, ThemeColors } from 'atomic/styles/theme.css'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'
import { FC } from 'react'
import * as css from './ProductHero.css'
import Image from 'next/image'
import { Trans } from 'next-i18next'
import { useCmsOrTranslation } from 'hooks/useSafeTranslation'
import { defaultCreateButtonKey } from 'types/LokalizeKeys'
import TrustpilotWidget from 'components/trustpilot/Trustpilot'
import InfoBox from './info_box/InfoBox'
import StartCreatingButton from 'components/start_creating_button/StartCreatingButton'
import useProductInfo from 'hooks/useProductInfo'

export type ProductType = 'book' | 'print'
export type Slide = {
  src: string
  altText: string
}

export type HeroSlide = string | Slide

type ProductHeroProps = {
  heading: string
  intro?: string
  productType: ProductType
  startCreateButtonText?: string
  heroSlides: HeroSlide[]
  infoList: keyof ThemeColors
} & BuilderProps

const ProductHero: FC<ProductHeroProps> = ({ heading, intro, productType, startCreateButtonText, heroSlides, infoList, ...props }) => {
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  const productInfo = useProductInfo()

  const slides = heroSlides?.map((slide) => (typeof slide === 'string' ? { src: slide, altText: '' } : slide))
  const printsBase = productInfo?.find((product) => product.key === 'card-13x18cm')
  const booksBase = productInfo?.find((product) => product.key === 'book-20x20cm-soft-cover')

  const printPrice = printsBase ? `${printsBase?.price} ${printsBase?.currency}` : '...'
  const bookPrice = booksBase ? `${booksBase?.price} ${booksBase?.currency}` : '...'

  const { cmsOrLokalise } = useCmsOrTranslation()

  return (
    <Box position="relative" {...cleanProps}>
      <Box className={css.carousel}>
        {slides && (
          <Carousel
            items={slides.map((slide, index) => {
              return (
                <Image
                  key={index}
                  alt={productType === 'book' ? cmsOrLokalise(slide.altText, 'photobooks_title') : cmsOrLokalise(slide.altText, 'photoprints_title')}
                  draggable={false}
                  priority={index === 0} // Only preload first image
                  fetchPriority={index === 0 ? 'high' : 'low'}
                  fill
                  sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 50vw`}
                  style={{ objectFit: 'cover' }}
                  src={slide.src}
                />
              )
            })}
          />
        )}
      </Box>
      <Container className={css.container}>
        <div></div>
        <Box paddingLeft={{ mobile: 'none', desktop: 'xxl' }} paddingTop={{ mobile: 'l', desktop: 'xxxl' }}>
          <Heading marginBottom="xs" as="h1">
            {heading}
          </Heading>
          <Text fontSize="l" as="p" marginBottom="s">
            {productType !== 'print' ? (
              <Trans
                key={'productpage_photobooks_subheading'}
                i18nKey={'productpage_photobooks_subheading'}
                values={{ price: bookPrice }}
                components={{
                  price: (
                    <Text as="span" fontWeight="semibold">
                      price
                    </Text>
                  ),
                }}
              />
            ) : (
              <Trans
                key={'productpage_prints_subheading'}
                i18nKey={'productpage_prints_subheading'}
                values={{ price: printPrice }}
                components={{
                  price: (
                    <Text as="span" fontWeight="semibold">
                      price
                    </Text>
                  ),
                }}
              />
            )}
          </Text>
          <Text fontSize="s" as="p" marginBottom="m">
            {cmsOrLokalise(intro, productType !== 'print' ? 'productpage_photobooks_body' : 'productpage_prints_body')}
          </Text>

          <StartCreatingButton
            productType={productType === 'print' ? 'print' : 'book'}
            buttonText={startCreateButtonText}
            defaultLocalKey={defaultCreateButtonKey}
          />

          <TrustpilotWidget variant="micro" marginBottom="xl" />

          <InfoBox
            background={infoList ?? 'mossa4'}
            keys={
              productType === 'print'
                ? Array.from({ length: 4 }).map((_, i) => `productpage_prints_factbox_line${i + 1}`)
                : Array.from({ length: 4 }).map((_, i) => `productpage_photobooks_factbox_line${i + 1}`)
            }
          />
        </Box>
      </Container>
    </Box>
  )
}
export default ProductHero
